<template>
  <div ref="panel" style="width: 100%;height: 100%;">

  </div>
</template>
<script>
import distribute from './mixins/distribute'
import format from '@/util/timeFormat'
export default {

  name: 'alarm-distribute',

  mixins: [distribute],

  methods: {

    _setOption () {
      const myChart = this.chartInstance

      let options = {

        visualMap: {
          show: true,
          right: 20,
          bottom: 20,
          textStyle: {
            color: '#FFFFFF'
          },
          // splitList,
          pieces: [
            { gte: 10, color: '#B51E1E' },
            { gte: 5, lt: 10, color: '#F2A50A' },
            { gte: 1, lt: 5, color: '#46D1F1' },
            { value: 0, color: '#0356D6' }
          ]
        },

        tooltip: {
          trigger: 'item',
          formatter: function (e) {
            const data = e.data
            const avg = format.timeStamp(data.avgReadedTime)
            const html = `
<div class="tooltip1">
      <div >
        <p>${data.name}</p>
        <p>报警数：${data.todayAlarmNum}，接警数：${data.todayReadedNum}，平均接警时间：${avg}</p>
      </div>
    </div>
`
            return html
          },

          backgroundColor: 'transparent',
          borderRadius: 4,
          borderWidth: 0,
          padding: 0,
          shadowColor: 'rgba(0, 0, 0, 0)',
          shadowBlur: 0,
          shadowOffsetX: 0,
          shadowQuality: 0,
          textStyle: {
            color: '#FFF'
          }
        },

        series: [
          {
            name: '中国',
            map: this.city,
            zoom: 1, // 当前视角的缩放比例
            roam: true, // 是否开启平游或缩放
            scaleLimit: { // 滚轮缩放的极限控制
              min: 0,
              max: 4
            },
            label: {
              show: true,
              color: '#FFF'
            },

            lineStyle: {
              type: 'solid',
              color: '#000',
              width: 0.5,
              opacity: 1
            },
            type: 'map',
            itemStyle: {
              normal: {
                shadowColor: '#0B368F',
                shadowBlur: 10
              }
            },
            data: this.getData()
            // 自定义名称映射
          }

        ]
      }
      options = { ...options, ...this.setting }
      myChart.setOption(options)
    },

    getData () {
      const data = []
      const list = this.districtList
      list.forEach(item => {
        let dataItem = {
          name: item.properties.name,
          todayAlarmNum: 0,
          todayReadedNum: 0,
          avgReadedTime: 0,
          value: 0
        }
        this.data.forEach(row => {
          if (row.eduName === dataItem.name) {
            dataItem = { ...dataItem, ...row }
          }
        })
        dataItem.value = dataItem.todayAlarmNum
        data.push(dataItem)
      })
      return data
    },
    randomData () {
      return Math.round(Math.random() * 600)
    }
  }
}
</script>
